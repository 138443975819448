import React, { useState } from 'react';
import './App.css';
import VideoConference from './components/VideoConference/VideoConference';
import Login from "./components/Login/Login";

function App() {

    const [user, setUser] = useState();

    if(!user) {
        return <Login setUser={setUser} />
    }

    return (
        <div className="App">
            <h1>Video Conference</h1>
            <VideoConference user={user}/>
        </div>
    );
}

export default App;
