import { post } from '../helpers/api-helper';
import { PROMOGA_LOGIN_API } from '../helpers/endpoint-helper';

export const login = async (userData) => {
    const url = `${process.env.REACT_APP_PROMOGA_STAGING_URL}${PROMOGA_LOGIN_API}`;
    try {
        const { data } = await post(url, userData);
        return data;
    } catch (exception) {
        console.error(exception);
        throw exception;
    }
};