import React from 'react';
import jwt from 'jsonwebtoken';

const VideoConference = ({user}) => {
    const jitsiContainerId = 'jitsi-container-id';
    const [jitsi, setJitsi] = React.useState({});
    const roomName = 'JitsiMeetAPIExample';
    const token = jwt.sign(
        {
            'context': {
                'user': {
                    'avatar': `${process.env.REACT_APP_PROMOGA_STAGING_URL}${user.image['50x50']}`,
                    'name': user.first_name,
                    'email': user.email,
                    'id': user.id
                }
            },
            'aud': process.env.REACT_APP_JWT_ACCEPTED_AUDIENCES,
            'iss': process.env.REACT_APP_JWT_ACCEPTED_ISSUERS,
            'sub': process.env.REACT_APP_PROMOGA_JITSI_URL,
            'room': roomName
        },
        process.env.REACT_APP_JWT_SECRET
    );

    const loadJitsiScript = () => {
        let resolveLoadJitsiScriptPromise = null;

        const loadJitsiScriptPromise = new Promise((resolve) => {
            resolveLoadJitsiScriptPromise = resolve;
        });

        const script = document.createElement('script');
        script.src = `${process.env.REACT_APP_PROMOGA_JITSI_URL}/external_api.js`;
        script.async = true;
        script.onload = resolveLoadJitsiScriptPromise
        document.body.appendChild(script);

        return loadJitsiScriptPromise;
    };

    const initialiseJitsi = async () => {
        if (!window.JitsiMeetExternalAPI) {
            await loadJitsiScript();
        }

        const _jitsi = new window.JitsiMeetExternalAPI(process.env.REACT_APP_PROMOGA_JITSI_URL.replace(/(^\w+:|^)\/\//, ''), {
            roomName: roomName,
            parentNode: document.getElementById(jitsiContainerId),
            jwt: token
        });

        setJitsi(_jitsi)
    };

    React.useEffect(() => {
        initialiseJitsi();

        return () => jitsi?.dispose?.();
    }, []);

    return <div id={jitsiContainerId} style={{ height: 720, width: '100%' }} />;
};

export default VideoConference;