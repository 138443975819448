import React, { useState } from 'react';
import { login } from '../../api/user';
import './Login.css';

const Login = ({setUser}) => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await login({
                email,
                password
            });
            const { user } = response;
            setUser(user);
            localStorage.setItem('user', JSON.stringify(user));
        } catch (e) {
            console.log(e)
        }

    }
    return(
        <div className="login-wrapper">
            <h1>Please Log In</h1>
            <form onSubmit={handleSubmit}>
                <label>
                    <p>Username</p>
                    <input type="text" onChange={e => setEmail(e.target.value)}/>
                </label>
                <label>
                    <p>Password</p>
                    <input type="password" onChange={e => setPassword(e.target.value)}/>
                </label>
                <div>
                    <button type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default Login;